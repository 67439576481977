import { ImageQuestionnaire } from 'components/icons';
import { Button } from 'components';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import _ from 'lodash';
import { useGetPublicTranslation } from 'api/hooks';
import { useTranslation } from 'react-i18next';

export default function Cover({ onQuestionnaireClick, onLearnMoreClick, className }) {
  const { t } = useTranslation();
  const { translation: title } = useGetPublicTranslation({ resourceKey: 'Landing', key: 'CoverTitle' });
  const { translation: description } = useGetPublicTranslation({ resourceKey: 'Landing', key: 'CoverDescription' });
  return (
    <div
      className={classNames(
        className,
        'flex bg-white px-5 md:px-16 py-8 md:py-28 rounded-xl flex-col md:flex-row mt-[120px] items-center md:space-x-14  xs:space-y-0 space-y-4 md:justify-between',
      )}
    >
      <div className="flex flex-col w-full md:w-1/2 mt-12 md:mt-0 mb-4">
        <span className="text-4xl md:text-6xl font-extrabold text-zinc-900 break-words sm:break-normal">
          {title?.text}
        </span>
        <div className="text-md md:text-lg text-base text-zinc-600 mt-6 whitespace-pre-line">{description?.text}</div>
        <div className={classNames('flex flex-col space-y-2 mt-9', 'xs:flex-row xs:space-x-6 xs:space-y-0')}>
          <Button radius="xl" color="green" onClick={onQuestionnaireClick}>
            {t('btn.questionnaire-complete')}
          </Button>
          <Button color="defaultAccent" radius="xl" onClick={onLearnMoreClick}>
            {t('btn.learn-more')}
          </Button>
        </div>
      </div>
      <ImageQuestionnaire className="md:h-4/6 w-52 xs:w-80 sm:w-[507px] md:w-max" />
    </div>
  );
}

Cover.propTypes = {
  onQuestionnaireClick: PropTypes.func,
  onLearnMoreClick: PropTypes.func,
  className: PropTypes.string,
};

Cover.defaultProps = {
  onQuestionnaireClick: _.noop,
  onLearnMoreClick: _.noop,
  className: '',
};
