/* eslint-disable no-unused-vars */
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDashboardStats } from 'api/hooks';
import { Paper, Skeleton } from '@mantine/core';
import { LineChart } from '@mantine/charts';
import _ from 'lodash';
import { ChartTitle } from './components';

const chartColors = ['indigo.6', 'yellow.4', 'emerald.5'];
export default function YearEvolutionStats({ title, queryKey, filter, withLegend }) {
  const { stats, isLoading } = useDashboardStats({ queryKey, ...filter });

  const chartData = useMemo(() => {
    if (!_.isEmpty(stats)) {
      const groups = _.groupBy(stats, 'mainCode');

      const test = _.transform(
        groups,
        (res, value, key) => {
          const monthName = new Date(2000, parseInt(key, 10) - 1).toLocaleString('ro-Ro', { month: 'long' });

          const item = { series: [] };
          item.monthName = monthName;
          _.forEach(value, (val, index) => {
            item[val.secondName] = val.total;
            item.series.push({ name: val.secondName, color: chartColors[index] });
          });
          _.forEach();
          res.push(item);
          return res;
        },
        [],
      );

      const data = _.map(test, (item) => _.omit(item, ['series']));
      return { series: test[0]?.series ?? [], data };
    }
    return { series: [], data: [] };
  }, [stats]);

  return (
    <Skeleton visible={isLoading}>
      <Paper shadow="xs" radius="6px" className="px-4 py-6 w-full">
        <div className="flex flex-col space-y-4">
          <ChartTitle title={title} />
          <LineChart
            withLegend={withLegend}
            h={300}
            gridAxis="xy"
            data={chartData.data}
            dataKey="monthName"
            series={chartData.series}
            curveType="bump"
          />
        </div>
      </Paper>
    </Skeleton>
  );
}
YearEvolutionStats.propTypes = {
  filter: PropTypes.object,
  queryKey: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  withLegend: PropTypes.bool,
};
YearEvolutionStats.defaultProps = {
  filter: {},
  withLegend: false,
};
