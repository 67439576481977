/* eslint-disable no-unused-vars */
import { useCallback } from 'react';
import { AppShell, Group, ActionIcon } from '@mantine/core';
import { useScrollIntoView, useWindowScroll, useMediaQuery } from '@mantine/hooks';
import { AppLogoImage, IconUser } from 'components/icons';

import { useTranslation } from 'react-i18next';
import { LocaleSelector, Layout, Button } from 'components';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from 'auth';
import { useGetPublicTranslation } from 'api/hooks';
import {
  Cover,
  VideoIntroductionContainer,
  CTAContainer,
  ExplanationContainer,
  PatientRights,
  LinksContainer,
  CTAContainerCards
} from './sections';

const layoutPadding = '2xl:px-14 md:px-12 px-2.5';

export default function Landing() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const hideAuthBtnText = useMediaQuery('(max-width: 530px)');
  const { translation: copyright } = useGetPublicTranslation({ resourceKey: 'Copyright', key: 'CopyrightText' });

  const { login, isLoggedIn } = useAuthContext();
  const navigateToQuestionnaire = useCallback(() => {
    navigate('/questionnaire');
  }, [navigate]);

  const { scrollIntoView, targetRef } = useScrollIntoView({});
  const scrollToExplanation = useCallback(() => {
    scrollIntoView();
  }, [scrollIntoView]);
  const [scroll] = useWindowScroll();

  return (
    <AppShell
      classNames={{ header: 'bg-transparent flex justify-center' }}
      styles={{ header: { backgroundColor: 'transparent' } }}
      header={{ height: '90px', offset: false }}
      withBorder={false}
      className='bg-vp-light-gray  max-[1395px]:px-8 max-[500px]:px-2.5'
    >
      <AppShell.Header>
        <div
          className={classNames(
            'h-full max-w-[1350px] flex-grow mt-5 rounded-xl bg-white shadow-md max-[500px]:mx-2.5 max-[1395px]:mx-8',
          )}
        >
          <Group h="100%" className={classNames(layoutPadding, 'max-w-[1350px] mx-auto')} justify="space-between">
            <AppLogoImage className="w-40 sm:w-max" />
            <div className="flex space-x-2 xsm:space-x-10">
              {!hideAuthBtnText && (
                <Button
                  color="defaultAccent"
                  className="text-base font-semibold text-gray-900 bg-transparent rounded-full border-vp-green-accent"
                  leftSection={<IconUser className="size-5" />}
                  onClick={isLoggedIn ? () => navigate('/dashboard') : login}
                >
                  {t('btn.auth')}
                </Button>
              )}
              {hideAuthBtnText && (
                <ActionIcon variant="transparent" className="text-gray-900">
                  <IconUser className="size-5" />{' '}
                </ActionIcon>
              )}
              <LocaleSelector.Switch />
            </div>
          </Group>
        </div>
      </AppShell.Header>
      <AppShell.Main>
        <div className="bg-vp-light-gray">
        <div className={classNames("mx-auto max-w-[1350px] h-full w-full bg-vp-light-gray overflow-x-hidden pt-[30px] flex flex-col space-y-12 md:space-y-20 pb-5")}>
          <Cover
            onQuestionnaireClick={navigateToQuestionnaire}
            onLearnMoreClick={scrollToExplanation}
          />
         <VideoIntroductionContainer 
            onQuestionnaireClick={navigateToQuestionnaire}/>
          <CTAContainer onQuestionnaireClick={navigateToQuestionnaire} />
          <ExplanationContainer  ref={targetRef} />
        <PatientRights translationKey="PatientRights" itemsSize={19} />
        <PatientRights
            className={layoutPadding}
            bgColor="yellow"
            translationKey="PatientResponsibilities"
            itemsSize={10}
        />
        <CTAContainerCards />
        <LinksContainer className={layoutPadding} />
         <div className={classNames('flex rounded-xl bg-white items-center justify-center py-9 mb-5')}>
            <span className="text-base font-medium text-zinc-400 text-center">{copyright?.text}</span>
          </div> 
        </div>

        </div>
      </AppShell.Main>
    </AppShell>
  );
}
