/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { ActionIcon } from '@mantine/core';
import { IconPlay, CTACardImage} from 'components/icons';
import classNames from 'classnames';
import { useGetPublicTranslation } from 'api/hooks';
import { Button } from 'components';
import { useTranslation } from 'react-i18next';

export default function CTAContainerCards({onQuestionnaireClick}) {
    const { translation: title } = useGetPublicTranslation({ resourceKey: 'Landing', key: 'CTAContainerCards' });

    const {t} = useTranslation()
    return (
      <div className={classNames('grid grid-cols-1 md:grid-cols-2')}>
        <div className="md:m-8 ml-0">
        <div className="flex flex-col py-12 md:py-20 justify-center bg-white rounded-xl space-y-6 px-2.5 md:px-16 ">
          <span className="text-4xl font-semibold text-zinc-900">{title?.text}</span>
          <Button radius="xl" color="blue" className="w-full md:w-min" onClick={onQuestionnaireClick}>
              {t('btn.questionnaire-complete')}
            </Button>
        </div>
        </div>

        {/* <div className="w"> */}
          <CTACardImage />
        {/* </div> */}
      </div>
    );
  }
  