/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { IconGuestAdd, IconAccessabilityCheck, IconPersonHeart, IconBuilding } from 'components/icons';

import classNames from 'classnames';
import { useGetPublicTranslation } from 'api/hooks';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const containerStyle = {
  backgroundImage: `url(/images/blue.png)`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
};
const icons = [
  {icon: IconPersonHeart, color: 'bg-vp-green-accent'}, 
  {icon: IconBuilding, color: 'bg-vp-light-yellow'},
  {icon: IconAccessabilityCheck, color: 'bg-vp-lightest-blue'}, 
  {icon: IconGuestAdd ,color: 'bg-vp-light-purple'}
];
const ExplanationContainer = forwardRef(function Explanation({ className }, ref) {
  const { translation: title } = useGetPublicTranslation({ resourceKey: 'Landing', key: 'ExplanationTitle' });

  const {t} = useTranslation()
  return (
    <div
      ref={ref}
      className={classNames(className)}
    >
      <div className="grid grid-cols-1 md:grid-cols-3 gap-7">
        <div className="min-h-[100px] bg-vp-light-blue text-center flex justify-center items-center rounded-xl col-span-1" style={containerStyle} >
          <span className="text-white text-4xl">{t('our-objectives')}</span>
        </div>
        <ExplanationGridItem key={`explanationItem[${1}]`} index={1} icon={icons[1].icon} iconColor={icons[1].color} />
        {_.map(_.range(4), (i) =>{ 
          
          return i !== 1 ? (
          <ExplanationGridItem key={`explanationItem[${i}]`} index={i} icon={icons[i].icon} iconColor={icons[i].color} />
        ) : null})}
      </div>
    </div>
  );
});

function ExplanationGridItem({ index, icon: Icon, iconColor }) {
  const { translation: title } = useGetPublicTranslation({
    resourceKey: 'Landing',
    key: `ExplanationItem[${index}]Title`,
  });
  const { translation: description } = useGetPublicTranslation({
    resourceKey: 'Landing',
    key: `ExplanationItem[${index}]Description`,
  });
  return (
    <div className={classNames("flex  bg-white py-9 px-12 rounded-xl space-x-7", {
      'md:col-span-2 md:flex-row ' : index === 1,
      "md:flex-col items-center md:space-y-7 md:space-x-0": index !== 1
      })}>
      <div className={classNames("flex-none self-start rounded-xl size-[72px] p-3 text-white", iconColor)}>
        <Icon className="w-full h-full" />
      </div>
      <div className="flex flex-col space-y-2.5">
        <span className={classNames("font-semibold text-xl")}>{title?.text}</span>
        <span className={classNames("text-base font-normal text-gray-500")}>{description?.text}</span>
      </div>

    </div>
  );
}

export default ExplanationContainer;

ExplanationContainer.propTypes = {
  className: PropTypes.string,
};
ExplanationContainer.defaultProps = {
  className: '',
};

ExplanationGridItem.propTypes = {
  index: PropTypes.number.isRequired,
  icon: PropTypes.any.isRequired,
  iconColor: PropTypes.string.isRequired
};
