import PropTypes from 'prop-types';
import { Button as MntButton } from '@mantine/core';
import classNames from 'classnames';

const styles = {
  primary: 'bg-emerald-500 hover:bg-emerald-600  p-4 px-6 h-min disabled:opacity-40 disabled:text-white',
  green: 'bg-vp-green-accent hover:bg-emerald-500  p-4 px-6 h-min disabled:opacity-40 disabled:text-white',
  blue: 'bg-vp-light-blue hover:bg-vp-light-blue/80 text-white p-4 px-6 h-min disabled:opacity-40 disabled:text-white',
  default:
    'bg-white hover:bg-zinc-50 text-zinc-700 p-4 px-6 h-min border border-zinc-300 disabled:opacity-40 disabled:text-zinc-700',
  defaultBorderless:
    'bg-white hover:bg-zinc-50 text-zinc-700 p-4 px-6 h-min  disabled:opacity-40 disabled:text-zinc-700',
  subtle:
    'bg-transparent hover:bg-slate-100 text-zinc-700 hover:text-zinc-700 p-4 px-6 h-min disabled:opacity-40 disabled:text-zinc-700',
  gradient:
    ' bg-clip-padding bg-gradient-to-r from-vp-emerald via-vp-yellow to-vp-red opacity-90 hover:opacity-100 p-4 px-6 h-min ',
  unstyled: '',
  defaultAccent: 'bg-white hover:bg-zinc-50 text-gray-800 p-4 px-6 h-min border border-vp-green-accent disabled:opacity-40 disabled:text-gray-700'
};
export default function Button({ color, children, className, ...rest }) {
  return (
    <MntButton
      className={classNames(styles[color], className, 'text-base')}
      {...rest}
      styles={{
        label: { overflow: 'visible' },
      }}
    >
      {children}
    </MntButton>
  );
}

Button.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  className: PropTypes.string,
};
Button.defaultProps = {
  children: null,
  color: 'default',
  className: '',
};
