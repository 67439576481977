/* eslint-disable no-unused-vars */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useGetPublicTranslation } from 'api/hooks';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const containerStyle = {
  backgroundImage: `url(/images/blue.png)`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
};

// TODO: Refactor
const linksMap = {
  'https://ms.gov.md/': '/images/ms-gov.png',
  'http://cnam.md/': '/images/cnam.png',
  'https://www.cna.md/':  '/images/cna.png',
  'https://tratatbine.md/': '/images/tratatbine.png',
}
export default function LinksContainer({ className }) {
  const { translation: title } = useGetPublicTranslation({ resourceKey: 'Landing', key: 'LinksTitle' });
  const { t } = useTranslation();

  const { translation: linksTitles } = useGetPublicTranslation({ resourceKey: 'Landing', key: /^Link\[\d+\]Title$/ });
  const { translation: linksLinks } = useGetPublicTranslation({ resourceKey: 'Landing', key: /^Link\[\d+\]Link$/ });

  return (
    <div
    className={classNames("grid grid-cols-1 md:grid-cols-3 gap-7")}
    >
    <div 
        className={classNames("bg-vp-light-blue row-span-2 text-center flex justify-center items-center rounded-xl col-span-1" )}
        style={containerStyle} 
    >
      <span className={classNames("text-4xl text-white")}>
        {title?.text}
      </span>
    </div>
        {_.map(_.take(linksTitles, 4), (linkTitle) => {
          const link = _.find(linksLinks, ['key', _.replace(linkTitle.key, 'Title', 'Link')]);
          return <LinkItem key={linkTitle?.text} title={linkTitle?.text} link={link?.text} />;
        })}
  </div>
  );
}

function LinkItem({ title, link }) {
  const logoStyles = {
    backgroundImage: `url(${linksMap[link]})`,
    backgroundSize: '100% 100%',
    backgroundPosition: 'center',
  }
  return (
    <div className=" bg-white rounded-xl p-7 flex space-x-5">
      <div style={logoStyles} className="size-12"/>
      <div className="flex flex-col">
        <span className="text-lg font-base">{title}</span>
        <a className="text-zinc-900 underline" href={link}>
          {link}
        </a>
      </div>
    </div>

  );
}
LinkItem.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
};
LinkItem.defaultProps = {
  title: '',
  link: '',
};
LinksContainer.propTypes = {
  className: PropTypes.string,
};
LinksContainer.defaultProps = {
  className: '',
};
