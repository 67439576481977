/* eslint-disable no-unused-vars */
import { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Button } from 'components';
import classNames from 'classnames';
import { IconCheck, IconChevronUp, IconChevronDown } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import { useGetPublicTranslation } from 'api/hooks';
import _ from 'lodash';

const containerStyle = {
  backgroundImage: `url(/images/green.png)`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
};
const containerStyleYellow = {
  backgroundImage: `url(/images/yellow.png)`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
};

export default function PatientRights({ translationKey, itemsSize, className, bgColor }) {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();
  const BtnIcon = expanded ? IconChevronUp : IconChevronDown;


  const { translation: translations } = useGetPublicTranslation({
    resourceKey: 'Landing',
    key: _.map(_.range(itemsSize), (i) => `${translationKey}Item[${i}]`),
  });

  const cardStyles = bgColor === 'yellow' ? containerStyleYellow : containerStyle;
  const cardBackgroundColor = bgColor === 'yellow' ? 'bg-vp-light-yellow' : 'bg-vp-green-accent'
  return (
    <div>
      <div
        className={classNames("grid grid-cols-1 md:grid-cols-3 gap-7")}
        >
        <div 
            className={classNames("min-h-[100px] row-span-2 text-center flex justify-center items-center rounded-xl col-span-1", cardBackgroundColor )}
            style={cardStyles} 
        >
          <span className={classNames("text-4xl", {
            'text-white': bgColor !== 'yellow',
            'text-zinc-900': bgColor === 'yellow'
          })}>
            { bgColor === 'yellow' ? t('patient-responsibilities') : t('patient-rights')}
          </span>
        </div>
        {_.take(translations, expanded ? 20 : 10)?.map((item) => (
          <CheckmarkLabel key={item.key} title={item?.text} />
        ))}
      </div>
      {translationKey === 'PatientRights' &&  <div className="flex items-center justify-center py-7 bg-transparent">
        <Button
          radius="xl"
          onClick={() => setExpanded((prev) => !prev)}
          rightSection={<BtnIcon className="size-5" />}
        >
          {expanded ? t('btn.learn-less') : t('btn.read-more')}
        </Button>
      </div>}
    </div>
  );
}

function CheckmarkLabel({ title }) {
  return (
    <div className="flex space-x-4 items-center bg-white rounded-xl px-7 py-6">
      <IconCheck className="size-6 text-emerald-500 flex-none" />
      <div className="flex flex-col space-y-2">
        <span className="text-lg font-base text-zinc-900">{title}</span>
      </div>
    </div>
  );
}
CheckmarkLabel.propTypes = {
  title: PropTypes.string.isRequired,
};
PatientRights.propTypes = {
  translationKey: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  className: PropTypes.string,
  itemsSize: PropTypes.number,
};
PatientRights.defaultProps = {
  bgColor: 'slate',
  className: '',
  itemsSize: 0,
};
