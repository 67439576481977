/* eslint-disable react/prop-types */
import { ActionIcon } from '@mantine/core';
import { IconPlay } from 'components/icons';
import classNames from 'classnames';
import ReactPlayer from 'react-player/youtube';
import { useGetPublicTranslation } from 'api/hooks';
import { Button } from 'components';
import { useTranslation } from 'react-i18next';

export default function VideoIntroductionContainer({onQuestionnaireClick}) {
  const { translation: title } = useGetPublicTranslation({ resourceKey: 'Landing', key: 'VideoExplanationTitle' });
  const { translation: description } = useGetPublicTranslation({
    resourceKey: 'Landing',
    key: 'VideoExplanationDescription',
  });
  const {t} = useTranslation()
  return (
    <div className={classNames('grid grid-cols-1 md:grid-cols-2 gap-8')}>
      <div className="flex flex-col py-12 md:py-20 justify-center bg-white rounded-xl space-y-6 px-16 ">
        <span className="text-5xl font-bold text-zinc-900">{title?.text}</span>
        <span className="text-lg font-base text-zinc-600">{description?.text}</span>
        <Button radius="xl" color="blue" className="w-min" onClick={onQuestionnaireClick}>
            {t('btn.questionnaire-complete')}
          </Button>
      </div>
      <div className="rounded-lg shadow-md  w-full max-w-[933px] min-h-[426px]  overflow-hidden">
        <ReactPlayer
          width="100%"
          height="100%"
          light
          url="https://www.youtube.com/watch?v=tgXn_nX5bTE"
          playIcon={
            <ActionIcon className="size-[7.5rem] rounded-full border border-white " color="slate.5">
              <IconPlay className="text-white size-14" />
            </ActionIcon>
          }
        />
      </div>
    </div>
  );
}
