import { Menu, UnstyledButton } from '@mantine/core';
import _ from 'lodash';
import { useLocale } from 'locale/hooks';
import classNames from 'classnames';
import { IconCheck } from '@tabler/icons-react';
import PropTypes from 'prop-types';

export default function LocaleSelector({ textColor, activeColor }) {
  const { locales, currentLocale, updateLocale } = useLocale();

  const onLocaleChange = (locale) => {
    updateLocale(locale.key);
  };
  return (
    <Menu width={200} shadow="md">
      <Menu.Target>
        <UnstyledButton className={`text-base font-semibold ${textColor} bg-transparent`}>
          {currentLocale.shortName}
        </UnstyledButton>
      </Menu.Target>

      <Menu.Dropdown>
        {_.map(_.values(locales), (locale) => {
          const isSelected = _.isEqual(currentLocale.shortName, locale.shortName);
          const CheckIcon = isSelected ? <IconCheck className="size-4" /> : null;
          return (
            <Menu.Item
              key={locale.key}
              component="div"
              rightSection={CheckIcon}
              className={classNames('text-base font-semibold', { [`bg-gray-50 ${activeColor}`]: isSelected })}
              onClick={() => onLocaleChange(locale)}
            >
              {locale.shortName}
            </Menu.Item>
          );
        })}
      </Menu.Dropdown>
    </Menu>
  );
}

function LocaleSelectorSwitch(){
  const { locales, currentLocale, updateLocale } = useLocale();

  const onLocaleChange = (locale) => {
    updateLocale(locale.key);
  };
  return <div className="flex space-x-2.5">
           {_.map(_.values(locales), (locale) => {
          const isSelected = _.isEqual(currentLocale.shortName, locale.shortName);
          return (
            <UnstyledButton  
               key={locale.shortName}
               onClick={() => onLocaleChange(locale)} 
               className={ classNames("text-base font-semibold bg-transparent", {
              "text-vp-green-accent": isSelected ,
              "text-vp-dark-gray": !isSelected
            })}>
            {currentLocale.shortName}
          </UnstyledButton>
          );
        })}
  </div>
}

LocaleSelector.Switch = LocaleSelectorSwitch
LocaleSelector.propTypes = {
  textColor: PropTypes.string,
  activeColor: PropTypes.string,
};
LocaleSelector.defaultProps = {
  textColor: 'text-gray-900',
  activeColor: 'text-emerald-500',
};
